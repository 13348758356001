@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,600;0,800;1,100;1,500;1,800&display=swap);
.slick-track{
    display: inline-flex;
}
.slick-slide .card {
    margin: 0 auto;
}
.slick-slider{
    position: relative;
}
.slick-list{
    overflow: hidden;
    padding:5px;
    text-align: center;
}
.carousel-events .slick-list{
    margin: 0 -4px;
}
@font-face{
    font-family: 'decalled';
    src: local('decalled'), url(/static/media/decalled.2d97e806.ttf);
}
