.slick-track{
    display: inline-flex;
}
.slick-slide .card {
    margin: 0 auto;
}
.slick-slider{
    position: relative;
}
.slick-list{
    overflow: hidden;
    padding:5px;
    text-align: center;
}
.carousel-events .slick-list{
    margin: 0 -4px;
}